
import { defineComponent } from "vue";
import Garden from "./views/Garden.vue";

export default defineComponent({
  name: "App",
  components: {
    Garden,
  },
});
