
import { defineComponent } from "vue";
import Slot from "../components/Slot.vue";

interface SlotData {
  id: number;
  plant: string | null;
}

export default defineComponent({
  name: "Garden",
  components: {
    Slot,
  },
  data() {
    return {
      slots: [
        { id: 1, plant: null },
        { id: 2, plant: null },
        { id: 3, plant: null },
        { id: 4, plant: null },
        // Agrega más slots según sea necesario
      ] as SlotData[],
    };
  },
  methods: {
    plantInSlot(slotId: number, plant: string) {
      const slot = this.slots.find((s) => s.id === slotId);
      if (slot) {
        slot.plant = plant;
      }
    },
  },
});
