import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0fd4514e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "garden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slot = _resolveComponent("Slot")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slots, (slot) => {
      return (_openBlock(), _createBlock(_component_Slot, {
        key: slot.id,
        slot: slot,
        onPlant: _ctx.plantInSlot
      }, null, 8, ["slot", "onPlant"]))
    }), 128))
  ]))
}