<template>
  <g class="leaves">
    <template v-if="type === 'simple'">
      <ellipse
        v-for="n in quantity"
        :key="'simple-' + n"
        :cx="getLeafX(n)"
        :cy="getLeafY(n)"
        :rx="20"
        :ry="10"
        fill="lightgreen"
      />
    </template>
    <template v-else-if="type === 'compound'">
      <circle
        v-for="n in quantity"
        :key="'compound-' + n"
        :cx="getLeafX(n)"
        :cy="getLeafY(n)"
        :r="15"
        fill="lightgreen"
      />
    </template>
    <template v-else-if="type === 'fern'">
      <rect
        v-for="n in quantity"
        :key="'fern-' + n"
        :x="getLeafX(n) - 10"
        :y="getLeafY(n) - 5"
        width="20"
        height="10"
        fill="lightgreen"
      />
    </template>
    <template v-else-if="type === 'spiny'">
      <polygon
        v-for="n in quantity"
        :key="'spiny-' + n"
        :points="getSpinyLeafPoints(n)"
        fill="lightgreen"
      />
    </template>
  </g>
</template>

<script>
export default {
  name: 'LeavesComponent',
  props: {
    type: { type: String, default: 'simple' },
    quantity: { type: Number, default: 10 },
    stemHeight: { type: Number, default: 150 },
    midLine: { type: Number, required: true }
  },
  methods: {
    getLeafX(n) {
      return 100 + 30 * Math.cos((2 * Math.PI * n) / this.quantity);
    },
    getLeafY(n) {
      return this.midLine - this.stemHeight + 30 * Math.sin((2 * Math.PI * n) / this.quantity);
    },
    getSpinyLeafPoints(n) {
      const x = this.getLeafX(n);
      const y = this.getLeafY(n);
      const points = `${x},${y} ${x + 5},${y + 10} ${x - 5},${y + 10}`;
      return points;
    }
  }
};
</script>

<style scoped>
.leaves {
  fill: none;
}
</style>
