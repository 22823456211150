<template>
    <g class="flower">
      <circle :cx="100" :cy="midLine - stemHeight" r="10" fill="pink" />
    </g>
  </template>
  
  <script>
  export default {
    name: 'FlowerComponent',
    props: {
      type: { type: String, default: '' },
      stemHeight: { type: Number, default: 150 },
      midLine: { type: Number, required: true }
    }
  };
  </script>
  
  <style scoped>
  .flower {
    fill: none;
  }
  </style>
  