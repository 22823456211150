
import { defineComponent, PropType } from "vue";
import PlantComponent from "./PlantComponent.vue";
import { generateRandomPlantConfig } from './../utils/randomPlantGenerator';


interface SlotData {
    id: number;
    plant: string | null;
}

export default defineComponent({
    name: "Slot",
    components: {
        PlantComponent,
    },
    data() {
        return {
            plantProps: null,
        }
    },
    props: {
        slot: {
            type: Object as PropType<SlotData>,
            required: true,
        },
    },
    methods: {
        plant() {
            // Para simplificar, siempre plantamos una "Rosa" en este ejemplo
            this.$emit("plant", this.slot.id, "Rosa");
            this.plantProps = generateRandomPlantConfig()
        },
    },
    mounted() {
        this.plant();
    }
});
