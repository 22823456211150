import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27ab8694"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "empty-slot"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlantComponent = _resolveComponent("PlantComponent")!

  return (_openBlock(), _createElementBlock("div", {
    class: "slot",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.plant && _ctx.plant(...args)))
  }, [
    (_ctx.slot.plant)
      ? (_openBlock(), _createBlock(_component_PlantComponent, _normalizeProps(_mergeProps({ key: 0 }, _ctx.plantProps)), null, 16))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, "Empty Slot"))
  ]))
}