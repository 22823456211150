<template>
    <div class="plant">
      <svg :width="width" :height="height" :viewBox="'0 0 ' + width + ' ' + height" preserveAspectRatio="xMidYMid meet">
        <!-- <line x1="0" :y1="halfHeight" :x2="width" :y2="halfHeight" stroke="black" stroke-width="1" /> -->
        <PotComponent :midLine="halfHeight" />
        <StemComponent :height="stemHeight" :midLine="halfHeight" />
        <LeavesComponent :type="leafType" :quantity="leafQuantity" :stemHeight="stemHeight" :midLine="halfHeight" />
        <FlowerComponent v-if="hasFlowers" :type="flowerType" :stemHeight="stemHeight" :midLine="halfHeight" />
        <FruitComponent v-if="hasFruits" :type="fruitType" :stemHeight="stemHeight" :midLine="halfHeight" />
      </svg>
    </div>
  </template>
  
  <script>
  import PotComponent from './PotComponent.vue';
  import StemComponent from './StemComponent.vue';
  import LeavesComponent from './LeavesComponent.vue';
  import FlowerComponent from './FlowerComponent.vue';
  import FruitComponent from './FruitComponent.vue';
  
  export default {
    name: 'PlantComponent',
    components: {
      PotComponent,
      StemComponent,
      LeavesComponent,
      FlowerComponent,
      FruitComponent
    },
    props: {
      width: { type: Number, default: 200 },
      height: { type: Number, default: 400 },
      stemHeight: { type: Number, default: 150 },
      leafType: { type: String, default: 'simple' },
      leafQuantity: { type: Number, default: 10 },
      hasFlowers: { type: Boolean, default: false },
      flowerType: { type: String, default: '' },
      hasFruits: { type: Boolean, default: false },
      fruitType: { type: String, default: '' }
    },
    computed: {
      halfHeight() {
        return this.height / 2;
      }
    }
  };
  </script>
  
  <style scoped>
  .plant {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>
  