<template>
  <g class="pot" :transform="'translate(100, ' + midLine + ')'">
    <!-- Definición del gradiente -->
    <defs>
      <linearGradient id="potGradient" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" style="stop-color:#D9A97E;stop-opacity:1" />
        <stop offset="70%" style="stop-color:#B57E52;stop-opacity:1" />
      </linearGradient>
    </defs>
    <!-- Forma de la maceta centrada y reducida -->
    <path d="M-40 36 A24 24 0 0 0 40 36 L40 0 L-40 0 Z" fill="url(#potGradient)" stroke="#A5693E" stroke-width="2"/>
  </g>
</template>

<script>
export default {
  name: 'PotComponent',
  props: {
    midLine: { type: Number, required: true }
  }
};
</script>

<style scoped>
</style>
