<template>
  <g class="fruit">
    <ellipse :cx="100" :cy="midLine - stemHeight + 30" rx="10" ry="15" fill="orange" />
  </g>
</template>

<script>
export default {
  name: 'FruitComponent',
  props: {
    type: { type: String, default: '' },
    stemHeight: { type: Number, default: 150 },
    midLine: { type: Number, required: true }
  }
};
</script>

<style scoped>
.fruit {
  fill: none;
}
</style>
