// randomPlantGenerator.ts

import { PlantConfig, PlantOptions } from './randomPlantConfigTypes';
import { getPlantOptions } from './PlantFactory';

export function generateRandomPlantConfig(): PlantConfig {
  const plantOptions: PlantOptions = getPlantOptions();

  const stemHeight = getRandomItem(plantOptions.stemHeights);
  const leafType = getRandomItem(plantOptions.leafTypes);
  let leafQuantity = getRandomInt(5, 30);
  const hasFlowers = getRandomBool();
  let flowerType = '';
  const hasFruits = getRandomBool();
  const fruitType = '';

  // Validación: Si tiene flores, debe tener un tipo de flor específico
  if (hasFlowers) {
    flowerType = getRandomItem(plantOptions.flowerTypes);
  }

  // Validación: Si tiene hojas simples, limitar la cantidad de hojas a 10
  if (leafType === 'simple' && leafQuantity > 10) {
    leafQuantity = 10;
  }

  // Más validaciones pueden ser agregadas según los requisitos del proyecto

  return {
    stemHeight,
    leafType,
    leafQuantity,
    hasFlowers,
    flowerType,
    hasFruits,
    fruitType
  };
}

function getRandomItem<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRandomBool(): boolean {
  return Math.random() >= 0.5;
}
