<template>
  <g class="stem">
    <rect x="95" :y="midLine - height" width="10" :height="height" fill="green" />
  </g>
</template>

<script>
export default {
  name: 'StemComponent',
  props: {
    height: { type: Number, default: 150 },
    midLine: { type: Number, required: true }
  }
};
</script>

<style scoped>
.stem {
  fill: none;
}
</style>
