// PlantFactory.ts

import { PlantOptions } from './randomPlantConfigTypes';

const plantOptions: PlantOptions = {
  stemHeights: [10, 30, 50, 100, 120],
  leafTypes: ['simple', 'compound', 'fern', 'spiny'],
  flowerTypes: ['rose', 'apple_blossom', 'cactus_flower'],
  fruitTypes: ['apple', 'orange', 'berry']
};

export function getPlantOptions(): PlantOptions {
  return plantOptions;
}
